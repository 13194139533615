export const lingo = {
  heading: "IITR Lingo",
  content:
    "A non-exhaustive list of words present in the vocabulary of the campus junta! Don’t try to memorise them all, you’ll definitely get savvy with these, by the end of the semester knowingly or unknowingly.",
  cards: [
    {
      title: "Chapo",
      subhead: "noun",
      content:
        "Originally meaning chai-pakoda, the concept has now evolved to anything from small treats from your seniors in the bhavan canteens to expensive ones at Rustic House or Olive.\n<br>But, as a freshman you need not worry about all that. This can also help you bond with your seniors who are going to be an important part of your campus life. Most seniors would be happy to give you a chapo (especially if you are of the opposite sex) so you should take full advantage of this and get all the chapos you can.<br>\nAdvice: Spare the Sophomores. They are just as broke as you are. The 3rd/4th/5th years got all that good intern money. So, target them for the more expensive chapos.",
    },
    {
      title: "Faccha/Facchi",
      subhead: "noun",
      content:
        " Word used to address the super confused and overwhelmed crowd that enters R-Land after surviving the harsh and unforgiving life of JEE prep. Yes, this is what you guys will be called.",
    },
    {
      title: "Ghissu",
      subhead: "noun",
      content:
        "Term of endearment for the extremely hard-working and academically driven members of R-Land who don’t indulge themselves in trivial activities and have completely devoted their lives to the study of Engineering and the Sciences.<br>Advice: Always be friends with one but, never become one yourself.",
    },
    {
      title: "Matka",
      subhead: "noun",
      content:
        "Arguably the most misunderstood and mistreated faction of the R-landers. These are the M.Tech Students who will also act as your Teaching Assistants, so these guys have a portion of your grades in their hands. Hence, the hate from the B.Tech/IMS crowd.<br>Advice: These people are just a few years older than you are so being friends with them can be very beneficial to the Practicals and Tutorial components in some of your courses.",
    },
    {
      title: "Machax/Machau",
      subhead: "noun",
      content:
        "Term used to refer to the exceptionally talented and people in campus who take it upon themselves to make everyone else insecure with their outrageous achievements.<br>Advice: “<b>Don't</b> Compare, <b>Don't Expect Too Fast<b>, and <b>Don't<b> Worry\".",
    },
    {
      title: "Dassi,Nehli,Atthi,Satti,Chaggi,Panji,Chaugi",
      subhead: "noun",
      content:
        "These represent your grades from 10-4 respectively. In some circles these are closely associated with one’s competency, intellect and dictates people’s opinion of a person.",
    },
    {
      title: "Saanp",
      subhead: "noun",
      content:
        "The heathens who claim to be a part of your “gang” but end up scoring significantly better than the gang in the exams after pretending to be just as fucked as you are.",
    },
    {
      title: "GPL",
      subhead: "noun",
      content:
        " Unempathetic thrashing of one’s posterior to celebrate the joyous occasion of their birth. The weapon of choice is usually a chappal but can vary based on the kind of friends you have :)",
    },
    {
      title: "Kholu and Dhakkan",
      subhead: "noun",
      content:
        "The person who has the lowest rank in the branch is called the kholu. And the one with the highest is called the dhakkan. Although the name sometimes sticks, the sentiment involved and the association of one’s JEE rank soon dissipates. So, no matter what your rank might be you start with a level playing field in your branch.",
    },
  ],
}

export const todolist = [
  {
    title: "Visit old KB at night -",
    content:
      "There are many urban legends surrounding this eerie building near Govind bhawan. Over the years the curious inhabitants of R-land have cooked up quite a few stories to explain how the building got to its current state. Although there’s no evidence to support these outrageous claims, if haunted places and urban legends interest you, you should definitely pay old KB a visit at night and maybe decipher its mysteries yourself.",
  },
  {
    title: "CBRI ke Paronthe -",
    content:
      "Most people agree that CBRI has the best paronthe in the entire campus. When you get bored of the terrible ones served at the bhavan canteens you should visit CBRI to taste its famous paronthes for a change.",
  },
  {
    title: "Unplanned trips to mussoorie/rishikesh on a weekend -",
    content:
      "Making plans for trips during mid-term breaks or at the end of the semester is one thing but, nothing compares to the unplanned trips on weekends. Rishikesh and Mussoorie both being only a few hours away from Roorkee make for great options for blowing off steam after a hectic week of classes and work.",
  },
  {
    title: "Mafia/Cards in front of the main building -",
    content:
      "The main building is a great place to hangout with your friends at night. Anytime you and your friends want to play cards or Mafia or anything for that matter, you should gather in front of the main building. Caution: Do not step on the grass in front of the main building. If you accidentally step or sit in the grass, you might have to endure the full force of the guard’s wrath.",
  },
  {
    title: "Solani walk at 6am -",
    content:
      "If you end up pulling an all nighter with your friends, the best way to end it is by taking a walk to Solani park and watching the splendid sunrise along the banks of the Ganges.",
  },
  {
    title: "Snagging Extra Sweets in the mess-",
    content:
      "Of all the toxic and inedible things served in the bhavan messes, the sweets you get occasionally are the only source of delight that the messes have to offer. Trouble is you only get one. So, tricking the mess workers and getting an extra one is something you should definitely try. The extra one always tastes sweeter than the first one.",
  },
]

export const packinglist = [
  {
    title: "Clothing and Umbrella",
    type: "label-green",
    content:
      "Roorkee’s weather is quite humid in the period of July to September. Do carry clothes accordingly and you could get a shirt or two for your freshers’ . ",
  },
  {
    title: "Essential Electronics",
    type: "label-red",
    content:
      "Laptop, mobile phone, chargers, earphones. Although you can buy a laptop later, it’d be best to have one from the start.",
  },
  {
    title: "Naphthalene Balls",
    type: "label-blue",
    content:
      "Roorkee’s weather is quite humid in the period of July to September. Do carry clothes accordingly and you could get a shirt or two for your freshers’ . ",
  },
  {
    title: "Documents",
    type: "label-red",
    content:
      "Id proofs and passport size photographs. They are the most important requirement. Even if you forget everything else at home be sure to carry these.",
  },
  {
    title: "Wall Posters",
    type: "label-blue",
    content:
      "Cool wall posters and hangings to liven up the mundane plastered walls, add on some colorful LEDs to make a cosy discotheque in your hostel room!",
  },
  {
    title: "Daily Needs",
    type: "label-green",
    content:
      "Stationery and notebooks, Washing soap for clothes and utensils, Cloth drying clips, Mattress and pillow, Mosquito repellent/ Racquets, Optional (highly suggested): a Cycle",
  },
  {
    title: "Footwear",
    type: "label-red",
    content:
      "Chappals and sport shoes being the bare minimum, can be topped up with other fancy pairs if you like",
  },
  {
    title: "Pickles",
    type: "label-red",
    content:
      "Mummy ke haath ke pickles and non perishable snacks (you will surely be grateful for these as you embark on a camaraderie with the (in)famous mess ka khana)",
  },

  {
    title: "Utensils",
    type: "label-blue",
    content:
      "Few utensils like a mug, glass, plate, spoons, bowls, a kettle (comes handy in the event of a sore throat, chilly winters or late night maggi cravings) etc.",
  },
]

export const incampusCommute = {
  title: "In Campus Commute and Payments",
  content:
    "<p>Let’s face it, our college has one of the most beautiful campus in the whole nation, and it is quite possible for you to lose your way here if you’re too busy admiring the scenic beauty. So let’s break down all the areas of interest for once and for all. First off, there’s the beautiful main building. There’s no chance you are going to miss that architectural marvel (Shh, don’t step on the grass though, unless you want to be sent to Gulag ;) ). Then comes the MGCL, The Mahatma Gandhi Central Library, always ready to quench your thirst for knowledge or to aid in your exam prep. God forbid that you ever fall ill, but there’s the hospital to rush in for an emergency. Your stomach will be one happy soul on campus, for here you can find Alpahar, Nescafe or the canteens to satiate your hunger or hang out with friends You can always hit the CBRI’s canteen for some heavenly parathas too! Talking about hanging out, don’t forget to visit the Student’s club, MAC(Multi Activity Centre), Hobbies Club or the New SAC. Feeling down? Need to speak out to someone but are hesitant? Worry no more, the Wellness Centre or some moments of peace at the Church are always there to help you.</p>",
}

export const faqs = [
  {
    SNo: "01",
    criteria: "Roorkee",
    questions: [
      {
        title: "Why Roorkee?",
        content:
          "Roorkee is a small, quiet town having a low population. This is a real advantage, even though this might seem like a boring place for many initially. With time, people will see how nice this place is. Apart from having one of the best IITs, Roorkee is close to many tourist spots like Mussoorie, Nainital, Rishikesh and so on. The calmness of the place is really soothing, and the low pollution here is a bonus. Roorkee is definitely a good choice, and people from cities will find it as a pleasant change.",
        index: 0,
      },
      {
        title: "How good are placements for my branch?",
        content:
          "We know how much you are itching to know the answer to the question. <a href='/placements/analysis'>Here</a> are your placement stats. But keep this in mind before selecting a branch based on the placement data only. In general a better branch of discipline is the one which combines interest with opportunities. At the end of the next 10 years, who knows, the entire market scenario could change with a drastic shift in opportunities.",
        index: 1,
      },
      {
        title: "Is there a good coaching center for IAS/CAT/GRE in Roorkee?",
        content:
          "Yes. Good coaching centers are indeed available for both CAT and IAS. For CAT, centers like Career Launcher, TIME and Arete are available. For IAS, Dronacharya and Samkalp are available, with Samkalp generally considered the better coaching center.",
        index: 2,
      },
      {
        title: "What are the documents required during registration?",
        content:
          "All documents you need to bring would be specified in your offer letter. We also recommend you to bring along stationery like scissors and fevistick as many forms need to be filled up. Also, sharing scissors with someone might just lead to the making of a long-lasting friendship at Roorkee. Registration will involve opening an account at a bank, and your subject and hostel registration. Once this is done, you'll be issued an Identity card. Keep it carefully, it is your identification on campus for at least 4 years! You seriously can’t afford to lose it, else you will have to face the administration of IIT Roorkee. Congratulations! You're now a student at IIT-R!",
        index: 3,
      },
    ],
  },
  {
    SNo: "02",
    criteria: "Academic",
    questions: [
      {
        title: "How does grading happen?",
        content:
          "There is a concept of CGPA (Cumulative Grade Point Average) and SGPA (Semester Grade Point Average) calculated with the help of Course Credits and Grades for each course. Most courses (i.e. courses with a little more than a handful of students enrolled) have a Relative Grading System. In this system, you are evaluated against your peers to decide which grade you get. This does not mean that you'll have to fight it out with your classmates all the time; it means that even getting a 60% might be good enough to get a very good grade in a difficult course. There is one mid-sem exam, one end-sem exam, and class-work-sessional marks assigned for each course (the split up varies, but is told at the start of the course itself) and these add up to your final score. Using a statistical formula based on the mean and standard deviation of the scores of all the students in a course, a grade is assigned on a scale of 10. These grades then are averaged over a semester, weighted by the number of course credits, to get the SGPA. Average all the courses over all the semesters that have occurred until a certain time, and you get your CGPA at that point of time. Mathematically speaking, it is the weighted average of SGPA of each semester with the total credits of that semester. The number of credits for a course is basically a small number (like 3 or 4) which roughly denote how many hours you will be spending on lectures, tutorials, and practicals with a course. It is generally an indicator of how important a course probably is.",
        index: 4,
      },
      {
        title: "What is a good grade?",
        content:
          "Grades have always been a point of worry for students. It is essential to know that a grade does not define who you are. Keeping this fact in mind, it is generally a good idea to keep your grade above 7.5 as it ensures that you clear the cut offs for various CGPA dependent activities such as being a part of the college fest team or placement cell or competing in the student elections. A 7.5 or above CGPA also ensures good placement opportunities as it is generally the cut off for many Tier 1 companies. While a higher grade is somewhat of an advantage, it isn't worth it if that is what you only do in your years in college. A lot of the learning happens outside class, through interacting with others, taking part in groups and basically enjoying your time here. Sometimes, if you've done something outstanding in a non-course activity, companies might even ignore a difference (like between an X.6 and an X.4). Nonetheless, it is you who decide which grade is good for you, depending on your aspirations and future plans. If you intend to go for higher studies abroad and are looking for a scholarship, chances are, you need a CGPA above 8.5.",
        index: 5,
      },
      {
        title:
          "What are the steps I need to take to get a branch change? What rituals to perform and what deity to pray to?",
        content:
          "It is a general belief that a branch change is a big task and a very difficult thing to achieve. However with a little hard work and focus it is possible. The modus operandi for changing your branch is to get a good GPA. For this, firstly attend classes regularly. Though this might seem tedious and boring, it has its benefits. It helps prevent a last minute rush when exams are near. Secondly making proper notes is also important – apart from making you popular for your famous notes, it also helps summarize the subject easily, in your own words. Last but not least, sacrifices - yes, sorry to disappoint you but to change the branch sometimes you may have to miss some chapos (lingo at IITR for parties) or various events which happen round the semester in campus. You might even need to ditch the Pronites during Thomso. However, branch change at IITR happens at the end of the first semester itself, so it is only a semester for which you might have to miss these things. While religiously sacrificing everything materialistic is a good idea, do not forego recruitments to different groups, because it is these which will make your 4-5 years in college worth remembering. Instead, make your few sacrifices count using proper time management. The cutoffs for each branch vary each year and thus it is not possible to predict the branch for a particular grade point. However, the better the CGPA, the higher your chances. Even if you don’t get it, trying for branch change has its advantages because you end up with a good solid CGPA.",
        index: 6,
      },
    ],
  },
  {
    SNo: "03",
    criteria: "Campus",
    questions: [
      {
        title:
          "Wait, in the previous answer, you mentioned that chapo is a party in IITR lingo. What are the other common words I will hear at IITR?",
        content:
          "As mentioned earlier, chapo, originally meaning chai-pakoda, has been now extended to mean party or treat. Then you may be called ‘Faccha’ by some of your seniors, which simply means a fresher. <br>Amongst one of the questions asked by seniors in your branch intro, would be “who is the branch kholu?”. Kholu literally means opener, in IIT-R this term refers to the person who opens the institute and/or branch by fetching the best rank in JEE-Advance in the institute or the corresponding branch. <br>These are some of the words you would be welcomed with, in the campus. Besides these, there is ‘Ghissu’, which is used to describe a person who is the epitome of a nerd and is too cautious about his grades. The term is generally used incorrectly for the purpose of ego satisfaction by many and in which case, it can be conveniently ignored. One of the coolest among all these words is ‘machau’ or ‘macha diya’. The term is used for somebody who achieves something of great importance. Definitely, this is one of the words you would want to be tagged with.<br>These are some of the very common ones used in the campus. Eventually with your stay, you will be more familiar with the our slang.",
        index: 7,
      },
      {
        title: "Do seniors rag?",
        content:
          "UGC Regulation on Curbing the Menace of Ragging in Higher Educational Institutions, 2009 completely forbids ragging of any kind in an institute of higher education. What this means for you as a potential student is that there is absolutely no reason to be afraid. If you’re looking for a safe stay, you will definitely get that and if you’re not looking for a safe stay, hmmm... As a matter of fact, IIT Roorkee can boast of a really good senior-junior culture.",
        index: 8,
      },
      {
        title: "How is the mess food?",
        content:
          "Everything you might have heard about the mess food is a blatant lie. The prestigious culinary masters working behind the scenes in the kitchens manage to consistently produce edible sustenance which also manages to barely pass safety inspections by the Food Safety and Standards Authority of India.",
        index: 9,
      },
      {
        title: "Should I bring my laptop as soon as I join?",
        content:
          "It is recommended, but not necessary to bring one as soon as you join the college. It is highly dependent from person to person as to how dependent they have been on their laptops in their pre-college years and how much they will need it for their discipline of choice. If you own a laptop already, feel free to bring it, but if you don't - wait for a while to find a local computer genius in your batch to suggest the perfect laptop for you. Besides Computer Centre and library, all hostels have their own computer rooms which can easily fulfil your academic needs for the first semester at least.",
        index: 10,
      },
      {
        title: "How are the internet facilities in the hostels?",
        content:
          "All the hostel rooms are provided with LAN connections which provide one of the fastest internet speed inIndia. Trust us, you will miss the internet speed once you are back at your home. Besides LAN, all the common areas in the hostels like canteen, mess, visitors’ room etc. are provided with WiFi. Well, eventually you will realise that internet facilities are pretty decent and one of your greatest saviours from boredom while your 4 years stay at the campus.",
        index: 11,
      },
      {
        title: "How are the sports facilities?",
        content:
          "Sports is something that will never feature in your list of disappointments with Roorkee. IIT Roorkee boasts world class stadiums and courts for popular sports such as cricket, football, volleyball, tennis, squash, table tennis, badminton, hockey, basketball and athletics! And that’s not all, we even have a well maintained Olympic size swimming pool. Indoor sports lovers need not waver as the UG Club building houses all you would want in the form of chess, carrom, pool, snooker, billiards, foosball and table tennis. If that’s not enough, every hostel also houses a selection of the above facilities for your pleasure.",
        index: 12,
      },
    ],
  },
]
